#post .container-fluid {
   max-width: 100%;
   padding-left: 50px !important;
}

#post textarea,
#post input,
#post select {
   background-color: rgba(228, 228, 228, .3);
   border-radius: 8px;
   font-size: 14px;
   padding: 18px 23px;
   height: 60px;
   border: none;
}

#post video {
   background-color: #000;
   border-radius: 8px;
   height: 500px;
   width: 100%;
}

#post .image {
   object-fit: cover;
   border-radius: 8px;
   height: 500px;
   width: 100%;
}

#post iframe {
   border-radius: 8px;
   height: 500px;
   width: 100%;
}
