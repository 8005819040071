.register-form {
    display: block;
    margin: 0 auto;
    padding: 40px;
    background: #FFFFFF;
    border-radius: 24px;
    /* max-width: 400px; */
    max-width: 928px !important;
    width: 100%;
}


.mb-0-h6 {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.list-group-item p {
    font-size: 14px;
}



.register-form input[type="submit"] {
    border-radius: 16px;
    font-weight: 700;
    background-color: #F55B6A;
    color: #fff;
    border: none;
    transition: all ease .3s;
    width: 100% !important;
    font-size: 14px;
}

.register-form input[type="submit"]:hover {
    cursor: pointer;
}

.register-form .input-field {
    padding: 18px;
    border-radius: 8px;
    /* background: rgba(228, 228, 228, 0.3); */
    border: none;
    color: #11142D;
    border: 1px solid rgba(222, 222, 222, 1);
    /* width: 100%; */
    margin-top: 15px;
    font-size: 14px;
}

.register-form .input-field::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B2B3BD;
}

.StripeElement {
    margin-top: 15px;
    padding: 18px;
    border-radius: 8px;
    border: 1px solid rgba(222, 222, 222, 1);
  }
  
  .text-success {
      color: #F55B6A !important;
  }