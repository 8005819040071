.page-navigation {
    margin-top: 15px;
    min-height: 40px;
    width: 100%;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
    float: left;
    max-width: 100%;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 600;
}

.page-navigation .row {
    display: flex;
    align-items: center;
    height: 100%;
}
.page-navigation .col-md-auto {
    padding-left: 0px;;
}
