.alert {
    z-index: 1000;
    position: absolute;
    margin: auto;
    margin-top: 30px;
    max-width: 970px;
    border-radius: 12px;
    background-color: rgba(245, 91, 106, 0.4);
    backdrop-filter: blur(1px);
    font-weight: 600;
    color: #Fff;
}

@media (max-width:576px) {
    .alert {
        margin-left: 25px;
    }
}