#catalogue .container-fluid {
    max-width: 100%;
    padding-left: 50px !important;
 }
 

#catalogue-form textarea {
    background-color: rgba(228, 228, 228, .3);
    border-radius: 8px;
    font-size: 14px;
    padding: 18px 23px;
    height: 60px;
    border: none;
}

#catalogue-form select { 
    height: 250px !important;
}

#edit-catalogue-form select { 
    height: 250px !important;
}