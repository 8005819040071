@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
.page-navigation {
    margin-top: 15px;
    min-height: 40px;
    width: 100%;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
    float: left;
    max-width: 100%;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 600;
}

.page-navigation .row {
    display: flex;
    align-items: center;
    height: 100%;
}
.page-navigation .col-md-auto {
    padding-left: 0px;;
}

#post .container-fluid {
   max-width: 100%;
   padding-left: 50px !important;
}

#post textarea,
#post input,
#post select {
   background-color: rgba(228, 228, 228, .3);
   border-radius: 8px;
   font-size: 14px;
   padding: 18px 23px;
   height: 60px;
   border: none;
}

#post video {
   background-color: #000;
   border-radius: 8px;
   height: 500px;
   width: 100%;
}

#post .image {
   object-fit: cover;
   border-radius: 8px;
   height: 500px;
   width: 100%;
}

#post iframe {
   border-radius: 8px;
   height: 500px;
   width: 100%;
}

#catalogue .container-fluid {
    max-width: 100%;
    padding-left: 50px !important;
 }
 

#catalogue-form textarea {
    background-color: rgba(228, 228, 228, .3);
    border-radius: 8px;
    font-size: 14px;
    padding: 18px 23px;
    height: 60px;
    border: none;
}

#catalogue-form select { 
    height: 250px !important;
}

#edit-catalogue-form select { 
    height: 250px !important;
}
/* Specific post */
.post {
    margin-top: 30px;
}
.post .date {
    color: #808191;
    font-size: 14px;
    font-weight: normal;
}

.post-block {
    background-color: #FFFFFF;
    border: 1px solid #DDDDE0;
    border-radius: 12px;
    max-width: 700px;
    min-width: 250px;
    min-height: 150px;
    padding:30px;
}

.post-content {
    text-align: center;
}

.post-information {
    margin-top: 15px;
}

.post-block .tag {
    font-size: 14px;
    font-weight: normal;
    color: #808191;
    margin-bottom: 0px;;
    margin-top: 0px;
}

.post-block .title {
    margin-top: 0px;
    margin-bottom: 0px;;
    color: #11142D;
    font-size: 18px;
    font-weight: 700;
}

.post-block .description {
    font-size: 12px;
    font-weight: normal;
    color: #808191;
    margin-top: 5px;
    margin-bottom: 0px;;
}


.post-content img {
    border-radius: 8px;
    cursor: pointer;
    width:400px;
}

.post-content video {
    border-radius: 8px;
    height:300px;
    width: 400px;
}

.post-content .pdf {
    border-radius: 8px;
    width:400px;
    height:600px;
}

.ril-outer {
    z-index: 5;
}

/* #image-view {
    top:0;
    left:0;
    z-index: 1000000;
    position: fixed;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    padding-top:30px;
    padding-bottom:30px;
}
#image-view img {
    width: auto;
    height: auto;
    max-height:100%;
    max-width: 100%;
    border-radius: 3px;
}
#image-view svg {
    color: #FFF !important;
    position: absolute;
    right:40px;
    top:40px;
    cursor: pointer;
} */


.ReactModalPortal {
    z-index: 9999;
}


@media only screen and (max-width: 992px) {
    .feed {
        max-width: 700px;
        margin: auto;
        margin-top: 40px;
    }

    .feed-control {
        max-width: 700px;
        margin: auto;
        margin-top: -15px;
    }
    .post-block {
        max-width: 100% !important;
        margin: auto !important;
    }
}


@media only screen and (max-width: 455px) {
    .post-content img {
        width:350px;
    }
    
    .post-content video {
        max-width: 350px;
    }
    
    .post-content .pdf {
        width:350px;
    }
}

@media only screen and (max-width: 390px) {
    .post-content img {
        width:300px;
    }
    
    .post-content video {
        width:300px;
    }
    
    .post-content .pdf {
        width:300px;
    }
}
.alert {
    z-index: 1000;
    position: absolute;
    margin: auto;
    margin-top: 30px;
    max-width: 970px;
    border-radius: 12px;
    background-color: rgba(245, 91, 106, 0.4);
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    font-weight: 600;
    color: #Fff;
}

@media (max-width:576px) {
    .alert {
        margin-left: 25px;
    }
}
* {
  font-family: 'Open Sans', sans-serif;
}
body,
html {
    width: 100%;
    height: 100%;
    background-color: #FFF;
}

h1 {
  font-size: 28px;
  color: #000;
  font-weight: 600;
}

p {
  color: #5a5c69;
}

input {
  color: #11142D !important;
}

textarea {
  color: #11142D !important;
  resize: none;
  height: 250px !important;
}

input[type='submit'] {
  color: #FFF !important;
}

a:hover {
  text-decoration: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #c6c7c9;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c6c7c9;
}

::placeholder {
  color: #c6c7c9;
}

.modal-checkbox-container {
  max-height: 30px;
  margin-top: 15px;
  display: flex;
}

.modal-checkbox-container input {
  margin-top: -15px;
  margin-right: 10px;
}


#register-page {
  background-color: #f55b6a;
}

#login-page {
  background-color: #f55b6a;
  padding-top: 120px;
}

#onboarding-page {
  padding-top: 25px;
  padding-bottom: 25px;
}

#wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #FFF;
}

#menu-toggle {
  position: fixed;
  z-index: 100;
  height: auto;
  max-height: 50px;
  margin: 10px;
  padding: 10px 10px 5px 10px;
  display: none;
  background-color: #e9988b;
  background-image: linear-gradient(180deg, #e9988b 100%, #eb7179 50%);
  background-size: cover;
  transition: all ease 0.3s;
}

#menu-toggle span {
  width: 30px;
  height: 5px;
  background-color: white;
  margin-bottom: 5px;
  display: block;
  border-radius: 5px;
  transform-origin: center center;
  transition: all ease .3s;
  opacity: 1;
}

.budge {
  transform: translateX(15rem);
}

.firstSpan {
  transform: translateY(10px) rotate(-45deg);
}

.secondSpan {
  transform: translateY(-10px) rotate(45deg);
}

.thirdSpan {
  opacity: 0 !important;
}

.table-responsive {
  min-height: auto;
}

.table {
  padding-top: 0px;
  /* cursor: pointer; */
}

.releaseAdd {
  text-align: center;
}

#relAdd {
  margin-top: -10px;
}

#addCoupon {
  margin-top: -10px;
}

#addReward {
  margin-top: -10px;
}

/* row selection color */
.table tbody tr.highlight td {
  background-color: #ddd;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.clickable {
  cursor: pointer;
}

#invoiceTable .download {
  color: #f55b6a;
  cursor: pointer;
}

.action-buttons {
  padding: 15px 0px;
  margin-right: 20px;
  text-align: right;
}

#addErr, #editErr, #relAddErr, #relEditErr, #relDelErr, #domainDelErr, #addTeamErr, #couponAddErr, #rewardAddErr, #addPostErr, #catalogueAddErr, #catalogueEditErr {
  display: none;
}

#group_logo {
  display: block;
  margin: 0 auto;
  width: 200px;
}

#drop-area {
  border: 2px dashed #ccc;
  width: 100%;
  margin: 10px auto;
  padding: 20px;
}

#drop-area p {
  text-align: center;
}

#drop-area.highlight {
  border-color: rgb(136, 136, 136);
}

.my-form {
  margin-bottom: 10px;
}

#gallery {
  margin-top: 10px;
}

#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

#fileElem {
  display: none;
}

.container-fluid {
  margin-top: 21.5px;
  padding-left: 25px !important;
  max-width: 1000px;
}

.btn-main-type {
  border-radius: 16px;
  padding: 15px 55px;
  background-color: #F55B6A;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.btn-primary {
  border-radius: 16px;
  padding: 15px 55px;
  background-color: #F55B6A;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
}

.btn-primary:disabled {
  background-color: #F55B6A !important;
}

.btn-primary:hover {
  background-color: #F55B6A;
}

.btn-primary:active, .btn-primary:focus {
  background-color: #F55B6A !important;
  outline: none !important;
  box-shadow: none;
}


.btn-secondary {
  border-radius: 16px;
  padding: 15px 55px;
  background-color: #333333;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
}

.btn-secondary:disabled {
  background-color: #333333 !important;
}

.btn-secondary:hover {
  background-color: #333333;
}

.btn-secondary:active, .btn-secondary:focus {
  background-color: #333333 !important;
  outline: none !important;
  box-shadow: none;
}


.addProductexisting {
  border-radius: 24px;
  width: 450px;
  display: block;
  margin: 35px 0px 0px 0px;
  background-color: #FFF;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  padding-bottom: 25px;
  padding-top: 12.5px;
}

.addCouponBox {
  border-radius: 24px;
  width: 450px;
  display: block;
  margin: 35px 0px 0px 0px;
  background-color: #FFF;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  padding-bottom: 25px;
  padding-top: 12.5px;
}

.modal-content {
  border-radius: 24px;
  max-width: 550px;
  margin: auto;
  border: none;
}

#videoModal .modal-content{ 
  /* min-height: 75%; */
  /* max-height: 75%; */
  width: 75%;
  max-width: 800px;
  min-height:650px !important;
}

#videoModal .modal-dialog {
  max-width: 100%;
}

#videoModal .modal-body {
  /* background-color: red; */
}

#videoModal .description {
  font-size: 12px;
  color: black;
  margin-top: 15px;
  font-weight: normal;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* margin-left: 0px; */
}

#videoModal video {
  margin-top: -15px;
  /* margin-top: -120px; */
  background-color: black;
}

.progress-text {
  font-size: 14px;
  margin-bottom: 5px;
}

.progress-bar {
  background-color: #F55B6A;
}

.modal-header {
  border: none;
  padding-left: 85px;
  /* padding-right: 85px; */
}

.modal-header .close {
  position: absolute;
  /* margin: 45px 85px 10px 85px; */
  font-size: 30px;
  font-weight: 700;
  color: #11142D;
  opacity: 1;
  outline: none;
  top: 40px;
  right: 60px;
}

.bootbox-close-button {
  /* margin: 45px 85px 10px 85px; */
  font-size: 30px;
  font-weight: 700;
  color: #11142D;
  opacity: 1;
  outline: none;
  margin: 0px !important;
  position: absolute;
  right: 25px;
}

.modal-title {
  white-space: nowrap;
  /* margin: 65px 85px 10px 85px; */
  /* margin-left: 70px; */
  margin-top: 25px;
  color: #11142D;
  font-size: 22px;
  font-weight: 700;
}

.modal-body {
  padding-left: 85px;
  padding-right: 85px;
  padding-bottom: 25px;
  /* margin: 0px 90px 0px 85px; */
  font-size: 12px;
  color: #11142D;
  font-weight: 600;
}

.bootbox .modal-body {
  margin: 20px 0px 0px 0px;
  text-align: center;
}

.bootbox-body {
  font-size: 14px;
  color: #11142D;
  font-weight: 600;
}

.modal-body input {
  background-color: rgba(228, 228, 228, .3);
  /* border-radius: 8px; */
  font-size: 14px;
  padding: 18px 23px;
  border: none;
  height: 60px !important;
}

.modal-body #card-element {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  border: none;
  height: auto;
  display: block;
  width: 100%;
  height: auto;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  color: #11142D;
}

.input-group-addon {
  display: flex;
}

.modal-body .input-group-text {
  background-color: rgba(228, 228, 228, .3);
  border: none;
  font-size: 14px;
  color: #11142D;
}

.modal-body #addPlanInterval {
  background-color: rgba(228, 228, 228, .3);
  height: 58px;
  width: 100px;
}

.modal-body select {
  border: none;
  background-color: rgba(228, 228, 228, .3);
  height: 58px !important;
  font-size: 14px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #11142D;
}

#reladdDiscRoles, #addDiscRoles, #relUpgradePlans, #relAddOnPlans, #editUpgradePlans, #editAddOnPlans, #addCouponProductsValue, #relRemoveRoles, #editRemoveRoles, #editRoles {
  padding-top: 15px;
  height: 100px !important;
}

.modal-body small {
  font-size: 12px;
  color: #B2B3BD;
}

small a {
  color: #F55B6A;
}

.modal-body small a {
  color: #F55B6A;
}

.modal-footer {
  border: none;
  padding-bottom: 50px;
  text-align: center;
}

.bootbox .modal-footer {
  display: block;
  margin: 0 auto;
  float: none;
}

.card .list-group-item {
  margin-bottom: -12px;
  border: none;
}

.card .list-group {
  margin-top: -20px;
  /* margin-top: -20px;
  padding-left: 15px;
  padding-right: 15px; */
}

.card-text {
  margin-top: -10px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #F55B6A !important;
}

.card {
  border: none;
  border-radius: 24px;
  width: 100%;
  display: flex;
  margin: 55px 0px 0px 0px;
  background-color: #FFF;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  padding: 15px !important;
}

.card h2 {
  font-size: 23px;
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  color: #11142D;
}

.card-title {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

#coupons .card {
  /* padding-bottom: 60px !important; */
  width: 450px;
  margin-right: 60px;
}

#rewards .card {
  width: 450px;
  margin-right: 60px;
}

.card-header {
  /* color: #eb7179 !important; */
  font-weight: 700;
  background-color: #F8F9FC !important;
  font-size: 1rem;
}

#delErr {
  display: none;
}

#addErr {
  display: none;
}

#discord-form {
  margin-left: 18px;
  width: 100%;
}

#discord-form .form-row {
  /* margin-left: -70px; */
  /* background-color: green; */
}

#discord-form .form-group {
  margin-left: 5px;
  max-width: 650px;
  width: 100%;
}

#discord-form input {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  height: 60px;
  border: none;
}

#embed-form {
  margin-left: 18px;
  width: 100%;
}

#embed-form .form-row {
  /* background-color: green; */
}

#embed-form .form-group {
  margin-left: 5px;
  max-width: 650px;
  width: 100%;
}

#embed-form input {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  height: 60px;
  border: none;
}


#bot-form {
  margin-left: 18px;
  width: 100%;
}
#bot-form select {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  border: none;
  width: 100%;
  height: 60px;
}

#bot-form .form-row {
  /* background-color: green; */
}

#bot-form .form-group {
  margin-left: 5px;
  max-width: 650px;
  width: 100%;
}

#bot-form input {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  height: 60px;
  border: none;
}

#email-form {
  width: auto;
  max-width: 650px;
}
#developer-form {
  max-width: 650px;
}

#api-key-table .blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}


/* Sidebar */
#sidebar-wrapper {
  z-index: 1001;
  /* background-color: #e9988b;  */
  /* background-image: linear-gradient(180deg,#e9988b 10%,#eb7179 100%);  */
  background-color: #FAFAFA;
  /* background-image: linear-gradient(180deg, #e9988b 10%, #eb7179 100%); */
  background-size: cover;
  /* width: 225px; */
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin .25s ease-out;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  font-family: 'Open Sans', sans-serif;
  max-width: 242px !important;
}

a {
  cursor: pointer;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: #5a5c69;
}

#sidebar-wrapper .list-group {
  min-width: 15rem;
  width: auto;
  background-color: transparent !important;
  color: #FFF;
}

#sidebar-wrapper .list-group hr {
  width: 75%;
  display: block;
  margin: 0 auto;
  margin-top: -5px;
  background-color: rgba(255, 255, 255, .5);
}


#sidebar-logo {
  border: 1px solid #E4E4E4 !important;
  /* box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important; */
}

#nav-logo {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  display: block;
  margin: 0 auto;
}

.user-corner-panel {
  position: absolute;
  right: -25px;
  top: -12.5px;
  padding: 40px;
  z-index: 100;
}

.user-corner-panel img {
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

.user-corner-panel-text {
  height: 50px;
  float: right;
  font-size: 16px;
}

.user-corner-panel-text span:first-child {
  font-size: 16px;
  color: #11142D;
  font-weight: 700;
}

.user-corner-panel-text span:nth-child(3) {
  color: #808191;
  font-weight: 600;
  font-size: 16px;
}

.help-dropdown {
  position: absolute;
  left: -27.5px;
  top: 105px;
  padding: 20px 30px;
  border-radius: 24px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 0px 60px -30px rgba(0, 0, 0, 0.75);
  transition: all ease 0.3s;
}

.help-dropdown::before {
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  position: absolute;
  border-bottom: 20px solid #fff;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.help-dropdown ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.help-dropdown li:not(:last-child) {
  margin: 0px 0px 16px 0px;
  padding: 0px;
}

.help-dropdown li a {
  text-decoration: none;
  color: #808191;
  font-weight: 700;
  font-size: 12px;
  margin: 0px;
  transition: all ease 0.3s;
}

.help-dropdown li a:hover {
  color: #F55B6A;
}

#sidebar-wrapper .list-group-item {
  border: none;
  background-color: transparent;
  margin: 0px 20px 0px 20px;
  color: #808191;
  transition: all ease 0.3s;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  /* background-color: red; */
}

#sidebar-wrapper .list-group-item svg {
  height: 20px ;
  width: 20px;
  /* margin-right: 12px !important; */
}

/* 
#sidebar-wrapper .list-group-item:nth-child(10) {
  margin-top: 15px;
}

#sidebar-wrapper .list-group-item:nth-child(8) {
  margin-bottom: 15px;
} */

#sidebar-wrapper .list-group-item i {
  margin-right: 10px;
  color: #808191;
  transition: all ease 0.3s;
}

#stripe-form {
  margin-left: 18px;
  width: 100%;
}

#stripe-form .list-group-item {
  border: none;
  padding-left: 0px;
}

#stripe-form .list-group-item a {
  color: #F55B6A;
}


#stripe-settings-form {
  margin-left: 18px;
  width: 100%;
}

#stripe-settings-form .form-group {
  margin-left: 5px;
  max-width: 650px;
  width: 100%;
}

#stripe-settings-form input {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  height: 60px;
  border: none;
}

#stripe-settings-form select {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  border: none;
  color: #000 !important;
  display: flex !important;
  width: 100%;
}

#referralDiscount option {
  color: #000 !important;
  display: block !important;
}

.tax-rate .input-group-text {
  background-color: rgba(228, 228, 228, .3);
  border: none !important;
}

#taxRate {
  border-radius: 0px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.btn-second-type {
  text-align: center;
  width: 120px;
  font-size: 16px;
  font-weight: 700;
  color: #11142D;
  margin-right: 20px;
  border-radius: 16px;
  background-color: #E4E4E4;
  padding: 12px 33px;
}

.mt-4 {
  font-size: 34px;
  font-weight: 700;
  color: #11142D !important;
}

#sidebar-wrapper .list-group-item i:nth-child(2) {
  float: right;
  text-align: center;
  line-height: 28px;
}

#sidebar-wrapper .list-group-item:hover>span {
  color: #666774;
}

#sidebar-wrapper .list-group-item:hover>svg>path {
  fill: #666774;
}

.closed {
  display: none;
}

#page-content-wrapper {
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  transition: all ease 0.3s;
  margin-bottom: 25px;
  margin-left: 15rem;
  position: relative;
}

#users .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#settings .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#transactions .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}


#releases .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#cancels .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#coupons .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#coupons .coupon-container {
  padding-left: 30px !important;
}


#rewards .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#rewards .reward-container {
  padding-left: 30px !important;
}


#team .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#stripe .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#pixels .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#discord .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#overview .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#posts .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#subscription .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#user-page #user-profile-body {
  padding-left: 15px !important;
}

#user-page .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#user-profile-body {
  margin-top:-30px !important;
}


#affiliate .container-fluid {
  max-width: 100%;
  padding-left: 50px !important;
}

#addCouponDuration {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

#users #page-content-wrapper {
  max-width: 100% !important;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#wrapper.toggled #page-content-wrapper {
  margin-left: 15rem;
}

#sidebar-wrapper .mobile {
  display: none;
}

#wrapper.toggled #sidebar-wrapper .mobile {
  display: block;
}

@media (max-width: 1374px) {
  .stats {
    max-width: 970px !important;
  }
}

@media (min-width: 1374px) {
  .stats-right {
    margin-right: 65px
  }
}

@media (min-width: 992px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0 !important;
    min-height: 100%;
    /* margin-left: 0px; */
  }


  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

@media only screen and (max-width: 453px) {
  .stats h5 {
    font-size: 15px !important;
  }

  .stats span {
    font-size: 12px !important;
  }

  #connDomain {
    margin-top: 0px !important;
    width: 100% !important;
  }

  #delDomain {
    margin-top: 15px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 577px) {
  #selectFiles {
    margin-top: 15px !important;
    margin-left: 0px !important;
  }

  #botButton {
    width: 100% !important;
  }

  .btn-primary {
    width: 100%;
  }

  #rewards .card-footer button {
    width: 100%;
  }

  #coupons .card-footer button {
    width: 100%;
  }


  /* #rewards .card-footer button {
    width: 200px !important;
  }

  #coupons .card-footer button {
    width: 200px !important;
  } */
}

@media only screen and (max-width: 992px) {
  #page-content-wrapper {
    margin-left: 0 !important;
  }

  .sideshow {
    display: block !important;
  }

  .overview .col-sm-4 {
    margin-top: 10px;
  }

  .stats {
    /* margin: auto !important; */
    margin-top: 35px !important;
    max-width: 100% !important;
  }

  .create-header {
    width: 100%;
    padding-right: 10px;
  }

  .addProductexisting {
    width: 100% !important;
  }

  .addCouponBox {
    width: 100% !important;
  }

  #coupons .card {
    width: 100%;
    margin-right: 0px !important;
  }

  #rewards .card {
    width: 100%;
    margin-right: 0px !important;
  }

  #subscription .card {
    width: 100% !important;
    margin-right: 0px !important;
  }

  #discord-form .form-group {
    max-width: 100% !important;
  }


  #embed-form .form-group {
    max-width: 100% !important;
  }

  #stripe-settings-form .form-group {
    max-width: 100% !important;
  }

  #stripe-form .form-group {
    max-width: 100% !important;
  }


  #settings .form-control {
    max-width: 100% !important;
  }

  #termsBlock {
    max-width: 100% !important;
  }

  #search-bar-icon {
    width: 100%;
  }

  .color_block {
    max-width: 100% !important;
  }

  #selectFiles {
    margin-left: 15px !important;
  }

  #connDomain {
    margin-top: 30px !important;
    width: 100% !important;
  }

  #delDomain {
    margin-top: 15px !important;
    width: 100% !important;
  }

  #defaultDomain {
    margin-top: 15px !important;
    width: 100% !important;
  }

  #domain-form .table-responsive {
    max-width: 100% !important;
  }
  



}

.active-item span {
  color: #F55B6A !important;
}

.active-item>svg>path {
  fill: #F55B6A !important;
}

.active-item path {
  fill: #F55B6A !important;
}


/*  */
.hidden {
  display: none;
}

.input-group-prepend {
  margin-right: 10px;
}

.selectpicker {
  outline: none;
}

#page-content-wrapper .navbar {
  position: fixed;
  height: 80px !important;
  background-color: #F8F9FC !important;
  width: 100%;
  z-index: 1;
  left: 0px;
}

#page-content-wrapper .nav-link {
  color: #5a5c69 !important;
}

#page-content-wrapper .nav-link:hover {
  color: #3c62f9 !important;
}

/* Login page */
#navbar {
  height: 100px;
  background-color: #F8F9FC;
  border-bottom: 1px solid #DDDDE0;
  clear: both;
}

#navbar img {
  display: inline-block;
  margin: 20px;
  max-height: 50px;
  width: auto;
}

#navbar #loc-container {
  float: right;
  height: 100%;
  padding: 20px 100px 20px 0px;
}

#navbar #loc-container a {
  font-size: 16px;
  text-decoration: none;
  color: #000;
  transition: all ease .3s;
  display: inline-block;
}

#navbar #loc-container a:last-child {
  background-color: #eb7179;
  color: white;
  padding: 15px 20px;
  font-weight: 700;
  transition: all ease .3s;
}

#navbar #loc-container a i {
  margin-left: 10px;
}

#navbar #loc-container a:last-child:hover {
  transform: scale(1.1);
}

.login-form {
  display: block;
  margin: 0 auto;
  padding: 60px;
  padding-bottom: 30px;
  background: #FFFFFF;
  border-radius: 24px;
  max-width: 550px;
  width: 100%;
}

.login-form h1 {
  color: #11142D;
  font-weight: 600;
  font-size: 23px;
  margin-top: 35px;
  margin-bottom: 10px;
  /* margin: 20px; */
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

#register-form {
  display: block;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 24px;
}

#register-form h1 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
}

#register-form h2 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
}

#register-form select, .input-field, #card-element {
  background: #E4E4E4, 30% !important;
  border-radius: 8px;
  display: block;
  margin: 10px auto;
  width: 100%;
  height: auto;
  padding: 18px 25px;
  outline: none;
  border: 1px solid #DDDDE0;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #11142D;
}

.register-form input[type="submit"] {
  width: 60% !important;
}

.InputElement[name="cardnumber"] {
  background-color: transparent !important;
}

.register-box {
  margin-top: 80px;
}

#agreement {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #B2B3BD !important;
  display: inline-block;
  margin-top: 10px;
  text-align: left;
}

#agreement a {
  color: #F55B6A;
}

#signup-text {
  text-align: center;
  font-size: 12px;
  color: #B2B3BD !important;
  line-height: 16px;
  font-weight: 600;
  margin-top: 5px;
}

#signup-text a {
  color: #F55B6A;
}

.login-form select:required:invalid {
  color: #aab7c4;
}

.login-form select option {
  color: #000;
}

.login-form input[type="submit"] {
  border-radius: 16px;
  font-weight: 700;
  background-color: #F55B6A;
  color: #fff;
  border: none;
  transition: all ease .3s;
}

.login-form input[type="submit"]:hover {
  cursor: pointer;
}

.login-form .input-field {
  border-radius: 8px;
  background: rgba(228, 228, 228, 0.3);
  border: none;
  color: #11142D;
}

.login-form .input-field::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B2B3BD;
}

.login-form .input-field:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B2B3BD;
}

.login-form .input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B2B3BD;
}

.customcheck input[type=checkbox] {
  display: none;
}

.customcheck input[type=checkbox]:checked+.checklabel {
  background-color: #eb7179 !important;
  opacity: 1 !important;
}

#checkmark {
  height: 25px;
  width: 25px;
  border-radius: 6px;
  vertical-align: middle;
  background-color: #808191;
  opacity: 0.3;
  border: none;
  cursor: pointer;
}

.dataTables_filter {
  display: none;
}

/* ==========================================================
 * Loading Bars
 * =========================================================*/
.loading {
  width: 100px;
  height: 100px;
  margin: 30px auto;
  position: relative;
}

#search-bar-icon>.icon {
  position: absolute;
  top: 60%;
  left: 30px;
  transform: translateY(-100%);
  color: #808191;
}

.search-bar-icon>.icon {
  position: absolute;
  margin-top: 2.5px;
  left: 15px;
  transform: translateY(-100%);
  color: #808191;
}

#user-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}

#team-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}

#post-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}

#catalogue-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}


#catalogue-feed-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}

#cancel-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}


#release-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}

#coupon-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
}

#transactions-search {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 50px;
  height: 60px;
  border: none;
  width: 100%;
  margin-left: -15px !important;
}


#transactionsTable tr{
  background-color: transparent !important;
}

.dataTables_info {
  font-size: 16px !important;
  margin-top: 15px;
}

.dataTables_wrapper .col-md-7 {
  position: relative;
  margin-top: 15px;
}

table.dataTable thead th {
  border: none;
}

.addUserButton {
  width: 48px;
  height: 48px;
  padding: 17px;
  border-radius: 100%;
  background-color: #F55B6A;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.addUserButton svg {
  display: block;
  margin: 0 auto;
}

.setVisibilityButton {
  width: 48px;
  height: 48px;
  padding-top:12.5px;
  border-radius: 100%;
  background-color: #F55B6A;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.setVisibilityButton svg {
  display: block;
  margin: 0 auto;
}


.editUserButton {
  width: 48px;
  height: 48px;
  padding: 17px;
  border-radius: 100%;
  background-color: #F55B6A;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.editUserButton svg {
  display: block;
  margin: -2px auto 0px -2px;
}

.delUserButton {
  width: 48px;
  height: 48px;
  padding: 17px;
  border-radius: 100%;
  background-color: #F55B6A;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.delUserButton svg {
  display: block;
  margin: 0 auto;
}

.inviteTeammateButton {
  width: 48px;
  height: 48px;
  padding: 17px;
  border-radius: 100%;
  background-color: #F55B6A;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.inviteTeammateButton svg {
  display: block;
  margin-left: -2px;
  /* margin: -3px 3px 0px -3px; */
}

.exportUserButton {
  width: 48px;
  height: 48px;
  padding: 17px;
  border-radius: 100%;
  background-color: #F55B6A;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.exportUserButton svg {
  display: block;
  margin: -3px auto 0px -3px;
}

#userTable {
  font-size: 14px;
}

#userTable a {
  color: #f55b6a;
}

#userTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#userTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#userTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}

#teamTable {
  font-size: 14px;
}

#teamTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#teamTable a {
  color: #f55b6a;
}

#teamTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#teamTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}
#releaseTable {
  font-size: 14px;
}

#releaseTable .clickable {
  cursor: pointer;
}

#releaseTable a {
  color: #f55b6a;
}

#releaseTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#releaseTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#releaseTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}


#couponTable {
  font-size: 14px;
}

#couponable .clickable {
  cursor: pointer;
}

#couponable a {
  color: #f55b6a;
}

#couponTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#couponTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#couponTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}


#postTable {
  font-size: 14px;
}

#postTable .clickable {
  cursor: pointer;
}

#postTable a {
  color: #f55b6a;
}

#postTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#postTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#postTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}


#catalogueTable {
  font-size: 14px;
}

#catalogueTable .clickable {
  cursor: pointer;
}

#catalogueTable a {
  color: #f55b6a;
}

#catalogueTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#catalogueTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#catalogueTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}


#domain_table {
  font-size: 14px;
}

#domain_table .clickable {
  cursor: pointer;
}

#domain_table a {
  color: #f55b6a;
}

#domain_table td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#domain_table_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#domain_table_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}


#invoiceTable {
  font-size: 14px;
  /* max-width: 970px !important; */
}

#paymentTable {
  /* max-width: 970px !important; */
  font-size: 14px;
}

#invoiceTable a {
  color: #f55b6a;
}

#invoiceTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#invoiceTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}

#paymentTable {
  font-size: 14px;
}

#paymentTable a {
  color: #f55b6a;
}

#paymentTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#paymentTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}

#cancelTable {
  font-size: 14px;
}

#cancelTable .clickable {
  cursor: pointer;
}

#cancelTable a {
  color: #f55b6a;
}

#cancelTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#cancelTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#cancelTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}


#affiliateTable {
  font-size: 14px;
}

#affiliateTable td {
  vertical-align: middle;
}

#affiliateTable a {
  color: #f55b6a;
}

#affiliateTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#affiliateTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}

/*  */

#transactionsTable {
  font-size: 14px;
}

#transactionsTable .clickable {
  cursor: pointer;
}

#transactionsTable a {
  color: #f55b6a;
}

#transactionsTable td {
  white-space: nowrap !important;
  vertical-align:middle;
  padding-left: 20px;
}

#transactionsTable_next a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color: #fff;
}

#transactionsTable_previous a {
  border-radius: 8px;
  margin-left: 4px;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color: #11142D;
}

#transactionsTable_info {
  display: none;
}

.paginate_button {
  border-radius: 8px !important;
  margin-left: 6px !important;
  font-weight: bold;
}

.dataTables_paginate {
  padding-top: 15px !important;
}

.dataTables_paginate .next {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  color:#fff !important;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .next{
  color:#fff !important;
}

.dataTables_wrapper .dataTables_paginate .next:hover {
  background: #333333 !important;
}

.dataTables_wrapper .dataTables_paginate .previous{
  color:#11142D !important;
}

.dataTables_wrapper .dataTables_paginate .previous:hover{
  color:#11142D !important;
}



.disabled {
  opacity: 0.6 !important;
}

  #userTable_previous {
  border: 1px solid #E4E4E4 !important;
  background: #E4E4E4 !important;
  color:#11142D !important;
}

.dataTables_paginate .previous {
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4 !important;
  color:#11142D !important;
  cursor: pointer;
}



.dataTables_paginate a {
  font-size: 14px;
}


.teamTableScroll::-webkit-scrollbar-track {
  background-color: #E3E6EC;
  border-radius: 4px;
}

.teamTableScroll::-webkit-scrollbar {
  height: 5px;
  background-color: #F5F5F5;
}

.teamTableScroll::-webkit-scrollbar-thumb {
  background-color: #F55B6A;
  border-radius: 4px;
}

.invoiceTableScroll::-webkit-scrollbar-track {
  background-color: #E3E6EC;
  border-radius: 4px;
}

.invoiceTableScroll::-webkit-scrollbar {
  height: 5px;
  background-color: #F5F5F5;
}

.invoiceTableScroll::-webkit-scrollbar-thumb {
  background-color: #F55B6A;
  border-radius: 4px;
}

.paymentTableScroll::-webkit-scrollbar-track {
  background-color: #E3E6EC;
  border-radius: 4px;
}

.paymentTableScroll::-webkit-scrollbar {
  height: 5px;
  background-color: #F5F5F5;
}

.paymentTableScroll::-webkit-scrollbar-thumb {
  background-color: #F55B6A;
  border-radius: 4px;
}

.userTableScroll::-webkit-scrollbar-track {
  background-color: #E3E6EC;
  border-radius: 4px;
}

.userTableScroll::-webkit-scrollbar {
  height: 5px;
  background-color: #F5F5F5;
}

.userTableScroll::-webkit-scrollbar-thumb {
  background-color: #F55B6A;
  border-radius: 4px;
}

.releaseTableScroll::-webkit-scrollbar-track {
  background-color: #E3E6EC;
  border-radius: 4px;
}

.releaseTableScroll::-webkit-scrollbar {
  height: 5px;
  background-color: #F5F5F5;
}

.releaseTableScroll::-webkit-scrollbar-thumb {
  background-color: #F55B6A;
  border-radius: 4px;
}

table.dataTable.no-footer {
  border-bottom: none;
}


.dataTables_paginate span {
  display: none !important;
  color: #11142D;
  border: 1px solid #E4E4E4 !important;
  background-color: #E4E4E4;
}


#userTable .checkbox-container {
  margin-top: 15px;
}

#userTable .checkbox-input {
  width: 20px;
  height: 20px;
}



#domain-table .checkbox-container {
  margin-top: 15px;
  
}

#domain-table .checkbox-input-domain {
  width: 20px;
  height: 20px;
}


#userTable .checkbox-input-all-users {
  width: 20px;
  height: 20px;
}

#teamTable .checkbox-container {
  margin-top: 15px !important;
}

#teamTable .checkbox-input-team {
  width: 20px !important;
  height: 20px !important;
}


#postTable .checkbox-input-post {
  width: 20px !important;
  height: 20px !important;
}

#postTable .checkbox-input-all-posts {
  width: 20px !important;
  height: 20px !important;
}

#postTable .checkbox-container {
  margin-top: 15px !important;
}

#postTable .checkbox-input-post {
  width: 20px !important;
  height: 20px !important;
}


#catalogueTable .checkbox-container {
  margin-top: 15px;
}

#catalogueTable .checkbox-input-catalogue {
  width: 20px;
  height: 20px;
}


#releaseTable .checkbox-container {
  margin-top: 15px !important;
}

#releaseTable .checkbox-input-release {
  width: 20px !important;
  height: 20px !important;
}


#releaseTable .checkbox-input-all-releases {
  width: 20px !important;
  height: 20px !important;
}


#invoiceTable .checkbox-container {
  margin-top: 15px !important;
}

#invoiceTable .checkbox-input-team {
  width: 20px !important;
  height: 20px !important;
}

#paymentTable .checkbox-container {
  margin-top: 15px !important;
}

#paymentTable .checkbox-input-team {
  width: 20px !important;
  height: 20px !important;
}


#couponTable .checkbox-container {
  margin-top: 15px !important;
}

#couponTable .checkbox-input-coupon {
  width: 20px !important;
  height: 20px !important;
}


#settings input {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  border: none;
  height: 60px;
}

.tabIcon {
  background-color: rgba(228, 228, 228, .3);
  border-radius: 8px;
  font-size: 14px;
  padding: 18px 23px;
  border: none;
  height: 60px;
  cursor: pointer;
}

#settings small {
  font-size: 12px;
  color: #B2B3BD;
}


#subscription .card h2 {
  /* margin-top: 15px !important; */
}

#subscription .card {
  /* padding-left: 0px !important; */
  /* padding-right: 0px !important; */
  /* width: 450px; */
  /* right: 0px; */
  width: 450px;
  padding: 15px !important;
}

#settings .form-control {
  max-width: 650px;
  width: 100%;
}

#domain-table {
  width: 100%;
}

#domain-form .table-responsive {
  /* max-width: 650px; */
}

#domain-table td {
  /* white-space: nowrap !important; */
  vertical-align:middle;
}

#api-key-table {
  width: 100%;
}

#api-key-table .table-responsive {
  /* max-width: 650px; */
}

#api-key-table td {
  /* white-space: nowrap !important; */
  vertical-align:middle;
}

#termsBlock {
  max-width: 650px !important;
  width: 100%;
}

.sideshow {
  background-color: #F55B6A;
  padding: 20px;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  position: fixed;
  left: -5px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all ease .32s;
  display: none;
  z-index: 1000;
}

.sideshow i {
  color: #fff;
  font-size: 24px;
}

#settings-button .active-item svg {
  color: #F55B6A !important;
}

/* Analytics */
.overview {
  padding: 20px;
  padding-top: 25px;
  height: auto;
  border-radius: 24px;
  width: 100%;
  max-width: 970px;
  display: block;
  margin: 35px 0px 0px 0px;
  background-color: #FFF;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.overview .col-sm-4 {
  /* text-align: center; */
}

.overview h5 {
  margin-top: 10px;
  color: #25282B;
  font-weight: 700;
  font-size: 14px;
}

.overview span {
  color: #F55B6A;
  font-size: 20px;
  font-weight: 600;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 10%, #edeef1 20%, #f6f7f8 80%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shine;
  -webkit-animation-timing-function: linear;
}

.stats .box-shine {
  height: 200px !important;
  width: 100%;
}


#releases .shine {
  width: 50px !important;
}

.shine {
  height: 15px;
  margin-top: 5px;
  right: 0px !important;
  width: 50%;
}

@keyframes shine {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.stats-container {
  padding-left: 15px;
  padding-right: 15px;
}

.member-products {
  max-width: 970px !important;
}

.stats {
  height: 320px;
  width: 100%;
  max-width: 450px;
  border-radius: 24px;
  display: block;
  margin-top: 35px;
  /* background-color: #FFF; */
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  padding: 30px;
}

.stats .header {
  height: 22.5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stats h5 {
  color: #25282B;
  font-weight: 700;
  font-size: 20px;
}

.stats span {
  /* position: absolute; */
  color: #F55B6A;
  font-weight: 600;
}

.stats canvas {
  width: 100% !important;
  margin-top: 15px;
  max-height: 200px;
  min-height: 200px !important;
}

.stats .body span {
  font-size: 20px;
  display: none;
}

.stats .header span {
  font-size: 14px;
}

.color_block {
  height: 50px;
  /* margin-top:8px; */
  border-radius: 8px;
  /* width: 95%; */
  border: 1px solid #E4E4E4 !important;
  max-width: 650px;
}

#primary_color {
  padding: 0px !important;
  border: 1px solid #E4E4E4 !important;
  background-color: transparent !important;
}

#secondary_color {
  padding: 0px !important;
  border: 1px solid #E4E4E4 !important;
  background-color: transparent !important;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 8px;
}

.select::-ms-expand {
  display: none;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: 98% center;
  background-repeat: no-repeat;
  outline: none;
}

.user-overview {
  margin-top: 15px;
  padding: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  /* height: 135px; */
  border-radius: 24px;
  width: 100%;
  min-width: 350px;
  background-color: #FFF;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-around; */
}

.user-overview .col-sm-8 {
  display: flex;
  flex-direction: row;
}

.user-overview .col-sm-4 {
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.user-overview .col-sm-4 .btn {
  color: #11142D;
  background-color: #E4E4E4;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  height: 60px;
  width: 120px;
}

.user-page-picture-container-mobile {
  display: none;
}

@media (max-width:576px) {
  #users .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #settings .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #releases .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }

    
  #transactions .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  
  #coupons .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }

  #cancels .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  


  
  #coupons .coupon-container {
    padding-left: 30px !important;
  }
  
  
  #rewards .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #rewards .reward-container {
    padding-left: 30px !important;
  }
  
  
  #team .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #stripe .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }

  #pixels .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #discord .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #overview .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #posts .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #subscription .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }
  
  #user-page .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }

  #user-page .container-fluid {
    max-width: 100%;
    padding-left: 30px !important;
  }

  .user-overview {
    margin: auto;
    margin-top: 15px;
  }

  .user-page-picture-container-mobile {
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }

  .user-page-picture-container {
    display: none;
  }
  
  .user-overview .col-sm-8 {
    justify-content: center;
    text-align: center;
  }
  .user-overview .col-sm-4 {
    margin-top: 15px;
    align-items: center;
  }

  #user-profile-body {
    margin: auto
  }
}

.user-overview .dropdown-menu {
  position: absolute;
  display: none;
}

.display {
  display: block !important;
}

.user-overview .dropdown-menu a {
  cursor: pointer;
  color: #F55B6A;
}

.user-overview .dropdown-item:active {
  background-color: #F55B6A;
  color: #FFF !important;
}

.user-overview h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #11142D;
}

.user-profile-card {
  height: 350px;
  max-width: 420px;
  min-width: 350px;
  width: auto;
  white-space: nowrap;
  /* margin-left: -35px; */
  margin-right:30px !important;
}

.user-profile-card .list-group-item {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow-x: hidden;
  white-space: nowrap;
}

.user-profile-card a {
  color: #F55B6A;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.user-profile-card .list-group lines {
  margin-left: 15px;
  width: 150px !important;
}

@media (max-width: 1158px) {
  .user-profile-card {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0px !important;
    height: auto !important;
    margin-left: 0px;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #F55B6A;
}

input:focus + .slider {
  box-shadow: 0 0 1px #F55B6A;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.dropdown-menu-filter {
  position: absolute;
  left:200px;
  font-size: 14px;
}


.checkbox-menu li label {
  display: block;
  padding: 3px 10px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  margin:0;
  transition: background-color .4s ease;
}
.checkbox-menu li input {
  margin: 0px 5px;
  top: 2px;
  position: relative;
}

.checkbox-menu li.active label {
  background-color: #F55B6A !important;
  color: #FFF !important;
}

.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
  background-color: #F55B6A !important;
  color: #FFF !important;}

.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
  background-color: #F55B6A !important;
  color: #FFF !important;

}


#postTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.release-roles {
  display: flex;
}

.release-role {
  margin-right: 5px;
  color: #f55b6a;
  background-color: rgba(245, 91, 106, 0.3);
  font-size: 12px;
  border-radius: 3px;
  text-align: center;
  padding-left:5px;
  padding-right:5px;
  /* padding-left: 10px; */
}




.coupon-applies-to {
  display: flex;
}

.coupon-applies-to-product {
  margin-right: 5px;
  color: #f55b6a;
  background-color: rgba(245, 91, 106, 0.3);
  font-size: 12px;
  border-radius: 3px;
  text-align: center;
  padding-left:5px;
  padding-right:5px;
  /* padding-left: 10px; */
}


#login-page {
    padding-top: 120px;
    height: 100vh !important;
}


.register-form {
    display: block;
    margin: 0 auto;
    padding: 40px;
    background: #FFFFFF;
    border-radius: 24px;
    /* max-width: 400px; */
    max-width: 928px !important;
    width: 100%;
}


.mb-0-h6 {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.list-group-item p {
    font-size: 14px;
}



.register-form input[type="submit"] {
    border-radius: 16px;
    font-weight: 700;
    background-color: #F55B6A;
    color: #fff;
    border: none;
    transition: all ease .3s;
    width: 100% !important;
    font-size: 14px;
}

.register-form input[type="submit"]:hover {
    cursor: pointer;
}

.register-form .input-field {
    padding: 18px;
    border-radius: 8px;
    /* background: rgba(228, 228, 228, 0.3); */
    border: none;
    color: #11142D;
    border: 1px solid rgba(222, 222, 222, 1);
    /* width: 100%; */
    margin-top: 15px;
    font-size: 14px;
}

.register-form .input-field::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B2B3BD;
}

.register-form .input-field:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B2B3BD;
}

.register-form .input-field::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B2B3BD;
}

.StripeElement {
    margin-top: 15px;
    padding: 18px;
    border-radius: 8px;
    border: 1px solid rgba(222, 222, 222, 1);
  }
  
  .text-success {
      color: #F55B6A !important;
  }
